import { ComponentProps, FC } from 'react';
import { colors } from '../style/colors';
import { TileButton } from './TileButton';

type Props = {
    onClickClose?: () => void;
} & ComponentProps<'div'>;

const Modal: FC<Props> = ({
    style,
    children,
    onClickClose,
    ...props
}) => {
    return (
        <div
            style={{
                position: 'fixed',
                top: 24,
                bottom: 24,
                left: 24,
                right: 24,
                borderRadius: 16,
                borderStyle: 'solid',
                borderWidth: 2,
                borderColor: colors.dark,
                backgroundColor: colors.bright,
                boxShadow: '0 8px 32px rgba(0,0,0,0.5)',
                overflow: 'hidden',
                ...style
            }}
            {...props}
        >
            <div style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
            }}>
                {children}
                {onClickClose &&
                    <TileButton
                        style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            fontSize: 16,
                            backgroundColor: colors.accent3,
                            color: colors.dark,
                            position: 'absolute',
                            right: 16,
                            top: 16,
                        }}
                        onClick={onClickClose}
                    >
                        ╳
                    </TileButton>
                }
            </div>
        </div>
    );
};

export { Modal };