import jwtDecode from 'jwt-decode';
import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { config } from '../config';
import { useLocalStorage } from '../hooks/useLocalStorage';

type DecodedToken = {
    rawJwt: string;
    exp: number;
    iat: number;
    email: string;
};

type Auth = {
    token?: DecodedToken;
    actions: {
        login: (email: string, password: string) => Promise<void>;
        logout: () => void;
    };
    meta: {
        loggingIn: boolean;
    };
};

const AuthContext = createContext<Auth>(undefined);

function toUnixTimeStamp(date: Date): number {
    return Math.floor(date.getTime() / 1000);
}

const AuthProvider: FC = props => {
    const [token, setToken] = useLocalStorage<DecodedToken>('token', undefined);
    const [lastReceivedTokenTime, setLastReceivedTokenTime] = useLocalStorage<{ iat: number, receivedAt: number }>('last_received_token_time', undefined);
    const [loggingIn, setLoggingIn] = useState<boolean>(false);

    const login = useCallback(async (email: string, password: string) => {
        try {
            setLoggingIn(true);
            const response = await fetch(`${config.apiUrl}/api/authenticate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                }),
            });
            const { token: rawJwt } = await response.json();
            const jwtContent = jwtDecode<DecodedToken>(rawJwt);
            const now = new Date();
            setToken({ ...jwtContent, rawJwt });
            setLastReceivedTokenTime({
                iat: jwtContent.iat,
                receivedAt: toUnixTimeStamp(now),
            });
        } catch (e) {
            setLastReceivedTokenTime(undefined);
            throw e;
        } finally {
            setLoggingIn(false);
        }
    }, []);

    // refresh token at its half-life
    useEffect(() => {
        if (token && lastReceivedTokenTime) {
            const canRefresh = lastReceivedTokenTime.iat === token.iat;
            const lifetime = token.exp - token.iat; // in s
            const now = new Date();
            const nowUnix = toUnixTimeStamp(now); // in s
            // some pessimistic leeway... don't refresh when it'll be too tight!
            const gracePeriod = 30; // in s
            const notExpired = (lastReceivedTokenTime.receivedAt + lifetime - nowUnix - gracePeriod) > 0;
            if (canRefresh && notExpired) {
                const halfLife = lifetime / 2;
                const refreshIn = Math.max(0, (lastReceivedTokenTime.receivedAt + halfLife - nowUnix) * 1000); // in ms
                const timeout = setTimeout(async () => {
                    try {
                        const response = await fetch(`${config.apiUrl}/api/v1/refresh-token`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                'Authorization': `Bearer ${token.rawJwt}`,
                            },
                        });
                        const { token: rawJwt } = await response.json();
                        const jwtContent = jwtDecode<DecodedToken>(rawJwt);
                        setToken({ ...jwtContent, rawJwt });
                        setLastReceivedTokenTime({
                            iat: jwtContent.iat,
                            receivedAt: toUnixTimeStamp(now),
                        });
                    } catch (e) {
                        console.error(e);
                        logout();
                    }
                }, refreshIn);
                return () => {
                    clearTimeout(timeout);
                };
            } else {
                logout();
            }
        }
    }, [token, lastReceivedTokenTime]);

    const logout = useCallback(() => {
        setToken(undefined);
        setLastReceivedTokenTime(undefined);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                token,
                actions: { login, logout },
                meta: { loggingIn },
            }}
            {...props}
        />
    );
};

export { AuthContext, AuthProvider };