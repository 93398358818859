import { useCallback, useContext } from 'react';
import { config } from '../config';
import { AuthContext } from '../context/Auth';

export function useLink(): (containerId: string, addressId: string) => Promise<void> {
    const auth = useContext(AuthContext);
    return useCallback(async (containerId: string, addressId: string) => {
        const result = await fetch(`${config.apiUrl}/api/v1/link`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token.rawJwt}`,
            },
            method: 'POST',
            body: JSON.stringify({
                containerId,
                address: addressId,
            }),
        });
        const { status, message } = await result.json();
        if (!result.ok || status !== 200) {
            throw new Error(message);
        }
        return;
    }, [auth]);
}