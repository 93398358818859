import { useCallback, useContext } from 'react';
import { config } from '../config';
import { AuthContext } from '../context/Auth';

export function useGetAddress(): (addressId: string) => Promise<string> {
    const auth = useContext(AuthContext);
    return useCallback(async (addressId: string) => {
        const result = await fetch(`${config.apiUrl}/api/v1/address`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token.rawJwt}`,
            },
            method: 'POST',
            body: JSON.stringify({
                address: addressId,
            }),
        });
        const { data, status, message } = await result.json();
        if (!result.ok || status !== 200) {
            throw new Error(message);
        }
        return data?.address;
    }, [auth]);
}