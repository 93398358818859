import { ComponentProps, FC } from 'react';

type Props = ComponentProps<'div'>;

const Screen: FC<Props> = ({
    style,
    ...props
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'center',
                ...style,
            }}
            {...props}
        />
    );
};

export { Screen };
