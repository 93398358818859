import { FC } from 'react';
import { Header } from './components/Header';
import { Screen } from './components/Screen';
import { ContainerActivator } from './ContainerActivator';
import { AuthContext, AuthProvider } from './context/Auth';
import { Login } from './Login';
import './style/base.css';
import './style/reset.css';

const App: FC = () => {
    return (
        <AuthProvider>
            <Screen style={{ height: '100%', fontFamily: 'sans-serif' }}>
                <AuthContext.Consumer>
                    {({ token }) => token
                        ?
                        <>
                            <Header />
                            <ContainerActivator
                                style={{ flexGrow: 1 }}
                            />
                        </>
                        :
                        <Login
                            style={{ flexGrow: 1 }}
                        />
                    }
                </AuthContext.Consumer>
            </Screen>
        </AuthProvider>
    );
}

export default App;