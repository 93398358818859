import { ComponentProps, FC, FormEvent, useCallback, useContext, useState } from 'react';
import { Button } from './components/Button';
import { Input } from './components/Input';
import { AuthContext } from './context/Auth';
import { colors } from './style/colors';
import logoSrc from './assets/logo.svg';

type Props = Omit<ComponentProps<'form'>, 'onSubmit'>;

const Login: FC<Props> = ({
    style,
    ...props
}) => {
    const auth = useContext(AuthContext);

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        auth.actions.login(username, password);
    }, [username, password, auth]);

    return (
        <form
            {...props}
            onSubmit={handleSubmit}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                width: '100%',
                maxWidth: 320,
                backgroundColor: colors.main1,
                padding: 32,
                borderRadius: 4,
                borderStyle: 'solid',
                borderWidth: 2,
                borderColor: colors.dark,
                boxShadow: `inset 3px 3px 0 ${colors.bright}`,
            }}>
                <img src={logoSrc} style={{ width: 156, alignSelf: 'center', marginBottom: 24 }} />
                <h1 style={{
                    color: colors.dark,
                    fontSize: 32,
                    fontWeight: 700,
                    alignSelf: 'center',
                    marginBottom: 48,
                    textShadow: `2px 2px ${colors.bright}`,
                }}>Containerbeheer</h1>
                <Input
                    type="text"
                    placeholder="gebruikersnaam"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="paswoord"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <Button disabled={auth.meta.loggingIn}>
                    {auth.meta.loggingIn ? 'bezig...' : 'log in'}
                </Button>
            </div>
        </form>
    );
};

export { Login };
