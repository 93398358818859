import { ComponentProps, FC, useCallback, useContext } from 'react';
import logoSrc from '../assets/logo.svg';
import { AuthContext } from '../context/Auth';

type Props = ComponentProps<'header'>;

const Header: FC<Props> = (props) => {
    const auth = useContext(AuthContext);

    const handleClickLogout = useCallback(() => {
        if (confirm('Ben je zeker dat je wilt uitloggen?')) {
            auth.actions.logout();
        }
    }, [auth]);

    return (
        <header {...props}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '2rem',
                padding: '1rem',
            }}>
                <img
                    src={logoSrc}
                    style={{
                        width: 96,
                    }}
                />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1.5rem',
                }}>
                    <div style={{ fontSize: 14 }}>
                        {auth.token.email}
                    </div>
                    <div
                        onClick={handleClickLogout}
                        style={{ color: '#aaa', cursor: 'pointer', fontWeight: 700 }}
                    >
                        LOG UIT
                    </div>
                </div>
            </div>
        </header>
    );
};

export { Header };
