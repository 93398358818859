import { ComponentProps, FC } from 'react';

type Props = ComponentProps<'button'>;

const Button: FC<Props> = props => {
    return (
        <button
            {...props}
            style={{
                ...props.style,
                fontSize: 16,
                padding: 8,
            }}
        />
    );
};

export { Button };
