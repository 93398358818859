import { ComponentProps, CSSProperties, FC } from 'react';
import { colors } from '../style/colors';
import { MdError, MdCheckCircle, MdEdit } from 'react-icons/md';
import { FaBarcode, FaQrcode } from 'react-icons/fa';

const bgIconStyle: CSSProperties = {
    position: 'absolute',
    zIndex: 0,
    fontSize: 160,
    lineHeight: 0,
    textShadow: 'none',
};

type Props = {
    bgIcon?: 'success' | 'error' | 'barcode' | 'qrcode';
    onManualEntry?: () => void;
} & ComponentProps<'button'>;

const TileButton: FC<Props> = ({
    style,
    children,
    disabled,
    bgIcon,
    onManualEntry,
    ...props
}) => {
    return (
        <button
            {...props}
            style={{
                backgroundColor: 'black',
                appearance: 'none',
                border: 'none',
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                fontSize: 48,
                padding: 16,
                fontWeight: 700,
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: 2,
                borderColor: colors.dark,
                boxShadow: `inset 3px 3px 0 ${colors.bright}`,
                textShadow: '2px 2px rgba(255,255,255,0.75',
                color: colors.dark,
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.25 : 1,
                lineHeight: 1,
                position: 'relative',
                isolation: 'isolate',
                ...style,
            }}
        >
            {bgIcon === 'success' && <div style={bgIconStyle}><MdCheckCircle style={{ color: colors.success, opacity: 0.33 }} /></div>}
            {bgIcon === 'error' && <div style={bgIconStyle}><MdError style={{ color: colors.error, opacity: 0.33 }} /></div>}
            {bgIcon === 'barcode' && <div style={bgIconStyle}><FaBarcode style={{ color: colors.bright, opacity: 0.66 }} /></div>}
            {bgIcon === 'qrcode' && <div style={bgIconStyle}><FaQrcode style={{ color: colors.bright, opacity: 0.66 }} /></div>}
            {onManualEntry &&
                <div
                    style={{
                        position: 'absolute',
                        bottom: 4,
                        right: 4,
                        width: 48,
                        height: 48,
                        padding: 4,
                        backgroundColor: colors.bright,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 5,
                        borderStyle: 'solid',
                        borderWidth: 2,
                        borderColor: colors.dark,
                    }}
                    onClick={e => {
                        e.stopPropagation();
                        onManualEntry();
                    }}
                >
                    <MdEdit />
                </div>
            }
            <div style={{ zIndex: 1 }}>
                {children}
            </div>
        </button>
    );
};

export { TileButton };
