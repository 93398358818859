export const colors = {
    main1: '#BBD443',
    main2: '#A5BB3A',
    alt1: '#6BBBC6',
    alt2: '#4CA5B5',
    subtle1: '#D0F3F9',
    subtle2: '#EDFAFC',
    accent1: '#EEB54E',
    accent2: '#59A7DC',
    accent3: '#D15A95',
    accent4: '#764E29',
    error: '#F00',
    success: '#0F0',
    dark: '#181716',
    medium: '#9C9C9C',
    light: '#C1C1C1',
    bright: '#FFF',
};