import { ComponentProps, FC, FormEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { MdRestartAlt } from 'react-icons/md';
import { AddressCodeScanner } from './components/AddressCodeScanner';
import { BigMessage } from './components/BigMessage';
import { Button } from './components/Button';
import { ContainerCodeScanner } from './components/ContainerCodeScanner';
import { FormTitle } from './components/FormTitle';
import { Input } from './components/Input';
import { Modal } from './components/Modal';
import { TileButton } from './components/TileButton';
import { useGetAddress } from './hooks/useGetAddress';
import { useLink } from './hooks/useLink';
import { colors } from './style/colors';

const buttonInnerContentStyle = {
    fontSize: 16,
    marginTop: 16,
    textShadow: 'none',
};

const buttonInnerContentStyleLarge = {
    ...buttonInnerContentStyle,
    fontSize: 20,
};

type Props = ComponentProps<'div'>;

enum ScanTarget {
    ADDRESS,
    CONTAINER_ID,
};

const ContainerActivator: FC<Props> = ({
    style,
    ...props
}) => {
    const link = useLink();
    const [linking, setLinking] = useState(false);
    const [linkingDone, setLinkingDone] = useState(false);
    const [linkingError, setLinkingError] = useState<string>();
    const linkContainer = useCallback(async (addressId: string, containerId: string) => {
        try {
            setLinking(true);
            await link(containerId, addressId);
            setLinkingDone(true);
        } catch (e) {
            console.error(e);
            setLinkingError(e.message);
        } finally {
            setLinking(false);
        }
    }, [link]);

    const getAddress = useGetAddress();
    const [addressId, setAddressId] = useState<string>();
    const [containerId, setContainerId] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [addressError, setAddressError] = useState<string>();
    // get full address string from ID when ID is set
    useEffect(() => {
        // flag to prevent fetch racing
        let canceled = false;
        async function get() {
            if (addressId !== undefined) {
                try {
                    const address = await getAddress(addressId);
                    if (!canceled) {
                        setAddress(address);
                    }
                } catch (e) {
                    console.error(e);
                    setAddress(undefined);
                    setAddressError(e.message);
                }
            }
            else {
                setAddress(undefined);
            }
        }
        get();
        return () => {
            canceled = true;
        };
    }, [addressId, getAddress]);

    const [scanning, setScanning] = useState<ScanTarget>();
    const manualInputRef = useRef<HTMLInputElement>(null);
    const [manuallyEntering, setManuallyEntering] = useState<ScanTarget>();

    useLayoutEffect(() => {
        if (manuallyEntering !== undefined && manualInputRef.current) {
            manualInputRef.current.focus();
        }
    }, [manuallyEntering]);

    const submitManualValue = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            value: { value: string };
        };
        const value = target.value.value;
        const actionMap = {
            [ScanTarget.ADDRESS]: setAddressId,
            [ScanTarget.CONTAINER_ID]: setContainerId,
        };
        actionMap[manuallyEntering](value);
        setManuallyEntering(undefined);
    }, [manuallyEntering]);

    const reset = useCallback(() => {
        setAddressId(undefined);
        setContainerId(undefined);
        setAddressError(undefined);
        setLinkingError(undefined);
        setLinkingDone(false);
    }, []);

    const isValid = useMemo(() => (
        addressId !== undefined
        && addressError === undefined
        && containerId !== undefined
    ), [addressId, containerId]);

    const handleScanAddress = useCallback((addressId: string) => {
        setAddressId(addressId);
        setScanning(undefined);
    }, []);

    const handleScanContainer = useCallback((containedId: string) => {
        setContainerId(containedId);
        setScanning(undefined);
    }, []);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'stretch',
                    flexDirection: 'column',
                    gap: 16,
                    padding: 16,
                    ...style,
                }}
                {...props}
            >
                <TileButton
                    style={{
                        flexGrow: 1,
                        flexBasis: 0,
                        minHeight: 120,
                        backgroundColor: colors.accent1
                    }}
                    onClick={() => setScanning(ScanTarget.ADDRESS)}
                    bgIcon={addressError ? 'error' : address ? 'success' : 'qrcode'}
                    onManualEntry={() => setManuallyEntering(ScanTarget.ADDRESS)}
                >
                    <div>
                        <div>ADRES</div>
                        {addressId &&
                            <div style={buttonInnerContentStyle}>
                                {addressId}
                            </div>
                        }
                        {addressError &&
                            <div style={{ ...buttonInnerContentStyleLarge, color: colors.error }}>
                                {addressError}
                            </div>
                        }
                        {address &&
                            <div style={buttonInnerContentStyleLarge}>
                                {address}
                            </div>
                        }
                    </div>
                </TileButton>
                <TileButton
                    style={{
                        flexGrow: 1,
                        flexBasis: 0,
                        minHeight: 120,
                        backgroundColor: colors.accent2
                    }}
                    onClick={() => setScanning(ScanTarget.CONTAINER_ID)}
                    bgIcon={containerId ? 'success' : 'barcode'}
                    onManualEntry={() => setManuallyEntering(ScanTarget.CONTAINER_ID)}
                >
                    <div>
                        <div>CONTAINER</div>
                        {containerId &&
                            <div style={buttonInnerContentStyleLarge}>
                                {containerId}
                            </div>
                        }
                    </div>
                </TileButton>
                <div
                    style={{
                        height: 'calc(min(100px, 15%))',
                        display: 'flex',
                        alignItems: 'stretch',
                        flexDirection: 'row',
                        gap: 16,
                    }}
                >
                    <TileButton
                        style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            backgroundColor: colors.accent3,
                        }}
                        onClick={reset}
                        disabled={containerId === undefined && addressId === undefined}
                    >
                        <MdRestartAlt size="4.5rem" />
                    </TileButton>
                    <TileButton
                        style={{
                            flexGrow: 1,
                            backgroundColor: colors.main1,
                            color: colors.dark
                        }}
                        disabled={!isValid}
                        onClick={() => linkContainer(addressId, containerId)}
                    >
                        KOPPEL
                    </TileButton>
                </div>
            </div>
            {scanning === ScanTarget.ADDRESS &&
                <Modal
                    onClickClose={() => setScanning(undefined)}
                    style={{ backgroundColor: colors.dark }}
                >
                    <AddressCodeScanner
                        onScan={handleScanAddress}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </Modal>
            }
            {scanning === ScanTarget.CONTAINER_ID &&
                <Modal
                    onClickClose={() => setScanning(undefined)}
                    style={{ backgroundColor: colors.dark }}
                >
                    <ContainerCodeScanner
                        onScan={handleScanContainer}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </Modal>
            }
            {manuallyEntering !== undefined &&
                <Modal onClickClose={() => setManuallyEntering(undefined)}>
                    <form onSubmit={submitManualValue} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <FormTitle style={{ marginBottom: 12 }}>
                            {(manuallyEntering === ScanTarget.ADDRESS) && 'adres code'}
                            {(manuallyEntering === ScanTarget.CONTAINER_ID) && 'container code'}
                        </FormTitle>
                        <Input
                            ref={manualInputRef}
                            type={manuallyEntering === ScanTarget.ADDRESS ? 'text' : 'number'}
                            name='value'
                        />
                        <Button>ingeven</Button>
                        <p style={{ marginTop: 16, textAlign: 'center', color: colors.light }}>
                            {(manuallyEntering === ScanTarget.ADDRESS) && `voorbeeld: 1234-ABCD-EFGH`}
                            {(manuallyEntering === ScanTarget.CONTAINER_ID) && 'voorbeeld: 000032035004800602'}
                        </p>
                    </form>
                </Modal>
            }
            {linking &&
                <Modal>
                    <BigMessage>
                        bezig...
                    </BigMessage>
                </Modal>
            }
            {linkingDone &&
                <Modal onClickClose={reset}>
                    <BigMessage>
                        koppeling geslaagd
                    </BigMessage>
                </Modal>
            }
            {linkingError &&
                <Modal onClickClose={reset}>
                    <BigMessage style={{ color: colors.error }}>
                        error
                    </BigMessage>
                    <p style={{ textAlign: 'center' }}>
                        {linkingError}
                    </p>
                </Modal>
            }
        </>
    );
};

export { ContainerActivator };
