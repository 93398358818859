import { ComponentProps, FC } from 'react';

type Props = ComponentProps<'h1'>;

const FormTitle: FC<Props> = props => {
    return (
        <h1 {...props} style={{
            fontSize: 24,
            fontWeight: 700,
            textAlign: 'center',
            ...props.style,
        }} />
    );
};

export { FormTitle };
