import { BrowserQRCodeReader } from '@zxing/browser';
import { ComponentProps, FC, useLayoutEffect, useRef } from 'react';

type Props = {
    onScan: (addressId: string) => void;
} & ComponentProps<'div'>;

const AddressCodeScanner: FC<Props> = ({
    onScan,
    ...props
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useLayoutEffect(() => {
        if (videoRef.current !== null) {
            const codeReader = new BrowserQRCodeReader(undefined);
            const decode = codeReader.decodeFromVideoDevice(undefined, videoRef.current, (result) => {
                if (result !== undefined) {
                    onScan(result.getText());
                }
            });
            return () => {
                decode.then(controls => controls.stop());
            };
        }
    }, [onScan]);

    return (
        <div style={{ ...props.style }} {...props}>
            <video
                ref={videoRef}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                }}
            />
        </div>
    );
};

export { AddressCodeScanner };
