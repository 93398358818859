import { ComponentProps, FC, forwardRef } from 'react';

type Props = ComponentProps<'input'>;

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
    return (
        <input
            {...props}
            ref={ref}
            style={{
                ...props.style,
                fontSize: 16,
                padding: 8,
            }}
        />
    );
});

export { Input };
