import { BrowserMultiFormatOneDReader } from '@zxing/browser';
import { BarcodeFormat, DecodeHintType } from '@zxing/library';
import { ComponentProps, FC, useLayoutEffect, useRef } from 'react';

type Props = {
    onScan: (containerId: string) => void;
} & ComponentProps<'div'>;

const ContainerCodeScanner: FC<Props> = ({
    onScan,
    ...props
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useLayoutEffect(() => {
        if (videoRef.current !== null) {
            const hintMap = new Map();
            // limburgnet employs code128 barcodes
            hintMap.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.CODE_128]);
            // which follw the GS1 standard
            hintMap.set(DecodeHintType.ASSUME_GS1, true);
            const codeReader = new BrowserMultiFormatOneDReader(hintMap);
            const decode = codeReader.decodeFromVideoDevice(undefined, videoRef.current, (result) => {
                if (result !== undefined) {
                    const content = result.getText();
                    const containsOnlyNumbers = /^\d+$/.test(content);
                    if(containsOnlyNumbers) {
                        onScan(content);
                    }
                }
            });
            return () => {
                decode.then(controls => controls.stop());
            };
        }
    }, [onScan]);

    return (
        <div style={{ ...props.style }} {...props}>
            <video
                ref={videoRef}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    height: '1%',
                    width: '85%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(255,0,0,0.5)',
                }}
            />
        </div>
    );
};

export { ContainerCodeScanner };
