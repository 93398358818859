import { ComponentProps, FC } from 'react';

type Props = ComponentProps<'div'>;

const BigMessage: FC<Props> = props => {
    return (
        <div
            {...props}
            style={{
                fontSize: 72,
                textAlign: 'center',
                ...props.style
            }}
        />
    );
};

export { BigMessage };